import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-new-haven-connecticut.png'
import image0 from "../../images/cities/scale-model-of-yale-university-visitor-center-in-new-haven-connecticut.png"
import image1 from "../../images/cities/scale-model-of-yale-center-for-british-art-in-new-haven-connecticut.png"
import image2 from "../../images/cities/scale-model-of-knights-of-columbus-museum-in-new-haven-connecticut.png"
import image3 from "../../images/cities/scale-model-of-new-haven-green-in-new-haven-connecticut.png"
import image4 from "../../images/cities/scale-model-of-yale-university-art-gallery-in-new-haven-connecticut.png"
import image5 from "../../images/cities/scale-model-of-fort-nathan-hale-in-new-haven-connecticut.png"
import image6 from "../../images/cities/scale-model-of-beinecke-rare-book-&-manuscript-library-in-new-haven-connecticut.png"
import image7 from "../../images/cities/scale-model-of-visit-new-haven-in-new-haven-connecticut.png"
import image8 from "../../images/cities/scale-model-of-pez-visitor-center-in-new-haven-connecticut.png"
import image9 from "../../images/cities/scale-model-of-info-new-haven-visitor-center-in-new-haven-connecticut.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='New Haven'
            state='Connecticut'
            image={image}
            lat='41.3108'
            lon='-72.925'
            attractions={ [{"name": "Yale University Visitor Center", "vicinity": "149 Elm St, New Haven", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.3092142, "lng": -72.92568590000002}, {"name": "Yale Center for British Art", "vicinity": "1080 Chapel St, New Haven", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.3078876, "lng": -72.93087600000001}, {"name": "Knights of Columbus Museum", "vicinity": "1 State St, New Haven", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.3023979, "lng": -72.92438549999997}, {"name": "New Haven Green", "vicinity": "250 Temple St, New Haven", "types": ["park", "point_of_interest", "establishment"], "lat": 41.3081574, "lng": -72.92612429999997}, {"name": "Yale University Art Gallery", "vicinity": "1111 Chapel St, New Haven", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.30839, "lng": -72.93095799999998}, {"name": "Fort Nathan Hale", "vicinity": "36 Woodward Ave, New Haven", "types": ["point_of_interest", "establishment"], "lat": 41.2708047, "lng": -72.90330159999996}, {"name": "Beinecke Rare Book & Manuscript Library", "vicinity": "121 Wall St, New Haven", "types": ["library", "point_of_interest", "establishment"], "lat": 41.3115804, "lng": -72.9272603}, {"name": "Visit New Haven", "vicinity": "5960, 5 Science Park, New Haven", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.32398999999999, "lng": -72.92749700000002}, {"name": "PEZ Visitor Center", "vicinity": "35 Prindle Hill Rd, Orange", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.2636381, "lng": -72.9970798}, {"name": "Info New Haven Visitor Center", "vicinity": "1000 Chapel St, New Haven", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.3072342, "lng": -72.9288871}] }
            attractionImages={ {"Yale University Visitor Center":image0,"Yale Center for British Art":image1,"Knights of Columbus Museum":image2,"New Haven Green":image3,"Yale University Art Gallery":image4,"Fort Nathan Hale":image5,"Beinecke Rare Book & Manuscript Library":image6,"Visit New Haven":image7,"PEZ Visitor Center":image8,"Info New Haven Visitor Center":image9,} }
       />);
  }
}